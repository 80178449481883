var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "AdjustmentModal" },
    [
      _c("v-select", {
        staticClass: "AdjustmentModal__adjustment-select mb-14",
        attrs: {
          "aria-label": "Select input to choose adjustment option",
          "data-cy": "adjustment-modal-type-select",
          clearable: false,
          options: _vm.adjustmentOptions,
          placeholder: "Type:"
        },
        on: {
          input: function($event) {
            _vm.submitDisabled = false
          }
        },
        model: {
          value: _vm.adjustmentFilter,
          callback: function($$v) {
            _vm.adjustmentFilter = $$v
          },
          expression: "adjustmentFilter"
        }
      }),
      _c(
        "base-input",
        {
          staticClass: "mb-14",
          attrs: {
            "data-cy": "adjustment-modal-amount-input",
            instructions: _vm.instructions.ADJUSTMENT_INSTRUCTION,
            label: true,
            "label-for": "amount",
            valid: _vm.adjustmentValid
          },
          model: {
            value: _vm.adjustmentAmount,
            callback: function($$v) {
              _vm.adjustmentAmount = typeof $$v === "string" ? $$v.trim() : $$v
            },
            expression: "adjustmentAmount"
          }
        },
        [_vm._v("\n    Amount\n  ")]
      ),
      ["Overadvance", "Client Credit", "Fee", "Write Off"].includes(
        this.adjustmentFilter
      )
        ? [
            _c(
              "base-input",
              {
                staticClass: "mb-14",
                attrs: {
                  "data-cy": "adjustment-modal-notes-input",
                  label: true,
                  "label-for": "notes"
                },
                model: {
                  value: _vm.notes,
                  callback: function($$v) {
                    _vm.notes = typeof $$v === "string" ? $$v.trim() : $$v
                  },
                  expression: "notes"
                }
              },
              [_vm._v("\n      Notes\n    ")]
            )
          ]
        : _vm._e(),
      ["Release to 3rd Party"].includes(this.adjustmentFilter)
        ? [
            _c(
              "base-input",
              {
                staticClass: "mb-14",
                attrs: {
                  "data-cy": "adjustment-modal-3rd-input",
                  label: true,
                  "label-for": "third-party"
                },
                model: {
                  value: _vm.thirdParty,
                  callback: function($$v) {
                    _vm.thirdParty = typeof $$v === "string" ? $$v.trim() : $$v
                  },
                  expression: "thirdParty"
                }
              },
              [_vm._v("\n      3rd Party\n    ")]
            )
          ]
        : _vm._e(),
      ["Direct Payment by Client"].includes(this.adjustmentFilter)
        ? [
            _c(
              "label",
              {
                staticClass: "BaseInput__label uppercase",
                attrs: { for: "date" }
              },
              [_vm._v("\n      Date\n    ")]
            ),
            _c("base-date-picker", {
              staticClass: "mb-14 mt-10 width-100",
              attrs: {
                "data-cy": "adjustment-modal-date-input",
                id: "date",
                name: "date-received"
              },
              model: {
                value: _vm.dateReceived,
                callback: function($$v) {
                  _vm.dateReceived = $$v
                },
                expression: "dateReceived"
              }
            })
          ]
        : _vm._e(),
      ["Non-factored Payment"].includes(this.adjustmentFilter)
        ? [
            _c(
              "label",
              {
                staticClass: "BaseInput__label uppercase",
                attrs: { for: "batch_date" }
              },
              [_vm._v("\n      Batch Date\n    ")]
            ),
            _c("base-date-picker", {
              staticClass: "mb-14 mt-10 width-100",
              attrs: {
                "data-cy": "adjustment-modal-date-input",
                id: "batch_date",
                name: "date-received"
              },
              model: {
                value: _vm.dateReceived,
                callback: function($$v) {
                  _vm.dateReceived = $$v
                },
                expression: "dateReceived"
              }
            }),
            _c("base-debtor-search", {
              staticClass: "AdjustmentModal__debtor-input mb-14",
              attrs: { label: true, "label-for": "Debtor" },
              on: { selected: _vm.setDebtorID },
              scopedSlots: _vm._u(
                [
                  {
                    key: "label",
                    fn: function() {
                      return [_vm._v("\n        DEBTOR\n      ")]
                    },
                    proxy: true
                  }
                ],
                null,
                false,
                2512100337
              )
            }),
            _vm.debtorID === _vm.unknownDebtorID
              ? _c(
                  "base-input",
                  {
                    staticClass: "mb-14",
                    attrs: {
                      "data-cy": "adjustment-modal-debtor-name-input",
                      label: true,
                      "label-for": "debtor-name"
                    },
                    model: {
                      value: _vm.debtorName,
                      callback: function($$v) {
                        _vm.debtorName =
                          typeof $$v === "string" ? $$v.trim() : $$v
                      },
                      expression: "debtorName"
                    }
                  },
                  [_vm._v("\n      Debtor Name\n    ")]
                )
              : _vm._e(),
            _c(
              "base-input",
              {
                staticClass: "mb-14",
                attrs: {
                  "data-cy": "adjustment-modal-load-number-input",
                  instructions: _vm.instructions.LOAD_NUMBER_INSTRUCTION,
                  label: true,
                  "label-for": "load-number",
                  valid: _vm.loadNumberValid
                },
                model: {
                  value: _vm.loadNumber,
                  callback: function($$v) {
                    _vm.loadNumber = typeof $$v === "string" ? $$v.trim() : $$v
                  },
                  expression: "loadNumber"
                }
              },
              [_vm._v("\n      Load Number\n    ")]
            ),
            _c("base-toggle", {
              staticClass: "mb-14",
              attrs: {
                "active-option": _vm.paymentMethod,
                "data-cy": "adjustment-modal-check-toggle",
                toggles: _vm.paymentMethodToggles
              },
              on: {
                toggle: function($event) {
                  _vm.paymentMethod = $event
                }
              }
            }),
            _vm.paymentMethod === "check"
              ? _c(
                  "base-input",
                  {
                    staticClass: "mb-14",
                    attrs: {
                      "data-cy": "adjustment-modal-check-number-input",
                      instructions: _vm.instructions.CHECK_NUMBER_INSTRUCTION,
                      label: true,
                      "label-for": "check-number",
                      valid: _vm.checkValid
                    },
                    model: {
                      value: _vm.check,
                      callback: function($$v) {
                        _vm.check = typeof $$v === "string" ? $$v.trim() : $$v
                      },
                      expression: "check"
                    }
                  },
                  [_vm._v("\n      Check Number\n    ")]
                )
              : _vm._e()
          ]
        : _vm._e(),
      ["Debtor Claim"].includes(this.adjustmentFilter)
        ? [
            _c("base-debtor-search", {
              staticClass: "AdjustmentModal__debtor-input mb-14",
              attrs: { label: true, "label-for": "Debtor" },
              on: { selected: _vm.setDebtorID },
              scopedSlots: _vm._u(
                [
                  {
                    key: "label",
                    fn: function() {
                      return [_vm._v("\n        DEBTOR\n      ")]
                    },
                    proxy: true
                  }
                ],
                null,
                false,
                2512100337
              )
            })
          ]
        : _vm._e(),
      _c(
        "base-button",
        {
          staticClass: "bg-blue fc-white mr-6",
          attrs: {
            "data-cy": "adjustment-modal-submit-btn",
            disabled: !_vm.showButton || _vm.submitDisabled
          },
          on: { click: _vm.addAdjustment }
        },
        [_vm._v("\n    Submit\n  ")]
      ),
      _c(
        "base-button",
        {
          staticClass: "bg-light fc-white",
          attrs: { "data-cy": "adjustment-modal-cancel-btn" },
          on: { click: _vm.cancel }
        },
        [_vm._v("\n    cancel\n  ")]
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }