<template>
  <div class="AdjustmentModal">
    <v-select
      v-model="adjustmentFilter"
      @input="submitDisabled = false"
      aria-label="Select input to choose adjustment option"
      class="AdjustmentModal__adjustment-select mb-14"
      data-cy="adjustment-modal-type-select"
      :clearable="false"
      :options="adjustmentOptions"
      :placeholder="'Type:'"
    />

    <base-input
      v-model.trim="adjustmentAmount"
      class="mb-14"
      data-cy="adjustment-modal-amount-input"
      :instructions="instructions.ADJUSTMENT_INSTRUCTION"
      :label="true"
      :label-for="'amount'"
      :valid="adjustmentValid"
    >
      Amount
    </base-input>

    <template v-if="['Overadvance', 'Client Credit', 'Fee', 'Write Off'].includes(this.adjustmentFilter)">
      <base-input
        v-model.trim="notes"
        class="mb-14"
        data-cy="adjustment-modal-notes-input"
        :label="true"
        :label-for="'notes'"
      >
        Notes
      </base-input>
    </template>

    <template v-if="['Release to 3rd Party'].includes(this.adjustmentFilter)">
      <base-input
        v-model.trim="thirdParty"
        class="mb-14"
        data-cy="adjustment-modal-3rd-input"
        :label="true"
        :label-for="'third-party'"
      >
        3rd Party
      </base-input>
    </template>

    <template v-if="['Direct Payment by Client'].includes(this.adjustmentFilter)">
      <label
        class="BaseInput__label uppercase"
        for="date"
      >
        Date
      </label>
      <base-date-picker
        v-model="dateReceived"
        class="mb-14 mt-10 width-100"
        data-cy="adjustment-modal-date-input"
        id="date"
        name="date-received"
      />
    </template>

    <template v-if="['Non-factored Payment'].includes(this.adjustmentFilter)">
      <label
        class="BaseInput__label uppercase"
        for="batch_date"
      >
        Batch Date
      </label>
      <base-date-picker
        v-model="dateReceived"
        class="mb-14 mt-10 width-100"
        data-cy="adjustment-modal-date-input"
        id="batch_date"
        name="date-received"
      />
      <base-debtor-search
        @selected="setDebtorID"
        class="AdjustmentModal__debtor-input mb-14"
        :label="true"
        :label-for="'Debtor'"
      >
        <template v-slot:label>
          DEBTOR
        </template>
      </base-debtor-search>
      <!-- if debtor not found - they need to type the debtor's name -->
      <base-input
        v-if="debtorID === unknownDebtorID"
        v-model.trim="debtorName"
        class="mb-14"
        data-cy="adjustment-modal-debtor-name-input"
        :label="true"
        :label-for="'debtor-name'"
      >
        Debtor Name
      </base-input>
      <base-input
        v-model.trim="loadNumber"
        class="mb-14"
        data-cy="adjustment-modal-load-number-input"
        :instructions="instructions.LOAD_NUMBER_INSTRUCTION"
        :label="true"
        :label-for="'load-number'"
        :valid="loadNumberValid"
      >
        Load Number
      </base-input>
      <base-toggle
        @toggle="paymentMethod = $event"
        :active-option="paymentMethod"
        class="mb-14"
        data-cy="adjustment-modal-check-toggle"
        :toggles="paymentMethodToggles"
      />
      <base-input
        v-if="paymentMethod === 'check'"
        v-model.trim="check"
        class="mb-14"
        data-cy="adjustment-modal-check-number-input"
        :instructions="instructions.CHECK_NUMBER_INSTRUCTION"
        :label="true"
        :label-for="'check-number'"
        :valid="checkValid"
      >
        Check Number
      </base-input>
    </template>

    <template v-if="['Debtor Claim'].includes(this.adjustmentFilter)">
      <base-debtor-search
        @selected="setDebtorID"
        class="AdjustmentModal__debtor-input mb-14"
        :label="true"
        :label-for="'Debtor'"
      >
        <template v-slot:label>
          DEBTOR
        </template>
      </base-debtor-search>
    </template>

    <base-button
      @click="addAdjustment"
      class="bg-blue fc-white mr-6"
      data-cy="adjustment-modal-submit-btn"
      :disabled="!showButton || submitDisabled"
    >
      Submit
    </base-button>

    <base-button
      @click="cancel"
      class="bg-light fc-white"
      data-cy="adjustment-modal-cancel-btn"
    >
      cancel
    </base-button>
  </div>
</template>

<script>
// Packages
import moment from 'moment'
// Helpers
import {
  Balance,
  ClientBankAccount,
  Debtor,
} from '../utils/api'
import {
  transformStringNumberToNumber,
} from '../utils/helpers'
// Components
import BaseButton from './base-button.vue'
import BaseDatePicker from './base-date-picker.vue'
import BaseDebtorSearch from './base-debtor-search.vue'
import BaseInput from './base-input.vue'
import BaseToggle from './base-toggle.vue'
// Mixins
import {
  ValidationMixin
} from '../utils/validation-mixin'

export default {
  name: 'AdjustmentModal',

  components: {
    BaseButton,
    BaseDatePicker,
    BaseDebtorSearch,
    BaseInput,
    BaseToggle,
  },

  props: {
    id: {
      type: [Number, String],
      required: false,
      default: null,
    }
  },

  mixins: [ValidationMixin],

  data () {
    return {
      adjustmentAmount: '',
      adjustmentFilter: '',
      adjustmentOptions: [
        'Overadvance',
        'Client Credit',
        'Non-factored Payment',
        'Release of Funds',
        'Release to 3rd Party',
        'Direct Payment by Client',
        'Fee',
        'Debtor Claim',
        'Write Off'
      ],
      adjustmentValid: true,
      check: '',
      checkValid: true,
      dateReceived: null,
      debtorID: null,
      debtorName: null,
      loadNumber: '',
      loadNumberValid: true,
      notes: '',
      paymentMethod: 'check',
      paymentMethodToggles: ['Check', 'ACH'],
      submitDisabled: false,
      thirdParty: '',
      unknownDebtorID: null,
    }
  },

  watch: {
    paymentMethod (newValue) {
      if (newValue === 'ach') this.check = ''
    }
  },

  computed: {
    client () {
      return this.$store.getters.client
    },

    showButton () {
      if (!this.adjustmentFilter) return false

      if (['Overadvance', 'Client Credit', 'Fee', 'Write Off'].includes(this.adjustmentFilter)) {
        if (!this.notes) return false
      }

      if (['Release to 3rd Party'].includes(this.adjustmentFilter)) {
        if (!this.thirdParty) return false
      }

      if (['Direct Payment by Client'].includes(this.adjustmentFilter)) {
        if (!this.dateReceived) return false
      }

      if (['Non-factored Payment'].includes(this.adjustmentFilter)) {
        if (!this.debtorID) return false
        if (!this.debtorName) return false
        if (this.paymentMethod === 'check' && !this.check) return false
        if (!this.loadNumber) return false
        if (!this.dateReceived) return false
      }

      if (['Debtor Claim'].includes(this.adjustmentFilter)) {
        if (!this.debtorID) return false
      }

      if (!this.adjustmentAmount) return false

      return true
    }
  },

  methods: {
    async addAdjustment () {
      if (!this.validation()) return

      this.submitDisabled = true
      this.progressStart()

      try {
        const clientID = this.id ? this.id : this.client.id
        let balance
        if (this.adjustmentFilter === 'Non-factored Payment') {
          await this.nonFactoredPayment(balance, clientID)
        } else if (this.adjustmentFilter === 'Direct Payment by Client') {
          await this.directPaymentClient(balance, clientID)
        } else if (['Release of Funds', 'Release to 3rd Party'].includes(this.adjustmentFilter)) {
          // releaseFunds could stop adjustment from happening, so we have to return if false
          if (!await this.releaseFunds(balance, clientID)) {
            return
          }
        } else {
          // adjustment types: over advance, client credit, fee, debtor claim, write off
          balance = (await Balance.create({
            amount: transformStringNumberToNumber(this.adjustmentAmount),
            client_id: clientID,
            notes: this.notes ? this.notes : null,
            reason: this.adjustmentFilter.toLowerCase(),
            type: 'client adjustments',
          })).data
        }
        this.submitDisabled = false
        // give the queue a little time
        this.$emit('adjustment-created', balance)
        this.progressFinish()
      }
      catch (error) {
        this.captureSentryEvent(
          `${this.adjustmentFilter} Balance Adjustment Error`,
          {
            config: error.config,
            data: this.$data,
            details: error,
            props: this.$props,
            response: error.response,
          }
        )
        this.CError(error)
        this.requestFailure({ message: 'There was an issue creating a new adjustment' })
      }
    },

    cancel () {
      this.$emit('cancel')
    },

    async directPaymentClient (balance, clientID) {
      // Include the metadata for the date received field (track history)
      balance = (await Balance.create({
        amount: transformStringNumberToNumber(this.adjustmentAmount),
        client_id: clientID,
        metadata: {
          // DO NOT CHANGE THIS WITHOUT TALKING TO DJ OR BACKEND DEV
          date_received: moment(this.dateReceived).format('x'),
        },
        notes: `Received on ${moment(this.dateReceived).format('M/D/YYYY')}`,
        reason: this.adjustmentFilter.toLowerCase(),
        type: 'client adjustments',
      })).data
    },

    async nonFactoredPayment (balance, clientID) {
      this.unknownDebtorID = await Debtor.getUnknownDebtorId()
      // generate the notes string
      const transferType = this.paymentMethod === 'check' ? 'check' : 'ach'
      const notes = `${this.debtorID === this.unknownDebtorID ? 'Debtor not found:' : ''} ${this.debtorName},
        load ${this.loadNumber}
        via ${transferType} ${transferType === 'check' ? this.check : ''}
        on ${moment(this.dateReceived).format('M/D/YYYY')}`

      // Include the metadata for the debtor and load # fields (track history)
      balance = (await Balance.create({
        amount: transformStringNumberToNumber(this.adjustmentAmount),
        client_id: clientID,
        metadata: {
          date_received: moment(this.dateReceived).format('x'),
          debtor_id: this.debtorID,
          debtor_name: this.debtorName,
          load_number: this.loadNumber,
          transfer_type: transferType,
          check_number: this.check
        },
        notes,
        reason: this.adjustmentFilter.toLowerCase(),
        type: 'client adjustments',
      })).data
    },

    async releaseFunds (balance, clientID) {
      // If the balance is negative, don't let these options be submitted
      const currentBalance = (await Balance.getClientTotalBalance(clientID)).data.total
      if (currentBalance < 0) {
        this.submitDisabled = true
        this.requestInfo({ message: 'Client has a negative balance' })
        return false
      }

      // Cannot let the release of funds make their balance negative
      if (this.adjustmentAmount * 100 > currentBalance) {
        this.submitDisabled = true
        this.requestInfo({ message: 'Cannot release more funds than the client has available' })
        return false
      }

      // If client doesn't have an active bank, do not allow release of funds
      // "release to 3rd party" does not need to check for an active bank
      // because it does not send money out via the web application
      if (this.adjustmentFilter.toLowerCase() === 'release of funds') {
        try {
          const activeBank = (await ClientBankAccount.queryList({
            client_id: clientID,
          })).data.rows.filter(bank => bank.status === 'active')[0]

          if (!activeBank) {
            this.submitDisabled = true
            this.requestInfo({ message: 'Funds cannot be released until the client adds a bank account' })
            return false
          }
        }
        catch (error) {
          this.captureSentryEvent(
            `${this.adjustmentFilter} Balance Adjustment Bank Check Error`,
            {
              config: error.config,
              data: this.$data,
              details: error,
              props: this.$props,
              response: error.response,
            }
          )
          this.CError(error)
          this.requestFailure({ message: 'There was an issue getting the client\'s banks' })
        }
      }

      // Same request as the else statement API call below, but we must check for the client's
      // currentBalance on a "release of funds" or "release to 3rd party"
      balance = (await Balance.create({
        amount: transformStringNumberToNumber(this.adjustmentAmount),
        client_id: clientID,
        metadata: {
          // DO NOT CHANGE
          third_party: this.thirdParty ? this.thirdParty : undefined,
        },
        notes: this.notes ? this.notes : null,
        reason: this.adjustmentFilter.toLowerCase(),
        type: 'client adjustments',
      })).data

      return true
    },

    async setDebtorID (filter) {
      if (!filter) {
        this.debtorID = null
        return
      }
      this.debtorID = filter.id
      this.unknownDebtorID = await Debtor.getUnknownDebtorId()

      if (this.debtorID !== this.unknownDebtorID) this.debtorName = filter.name
      else this.debtorName = null
    },

    validation () {
      let valid = true

      if (['Non-factored Payment'].includes(this.adjustmentFilter)) {
        if (!this.isValidLoadNumber(this.loadNumber)) {
          this.loadNumberValid = false
          valid = false
        } else {
          this.loadNumberValid = true
        }

        if (this.paymentMethod === 'check' && !this.isValidCheckNumber(this.check)) {
          this.checkValid = false
          valid = false
        } else {
          this.checkValid = true
        }
      }

      if (!this.isValidAdjustment(this.adjustmentAmount)) {
        this.adjustmentValid = false
        valid = false
      } else {
        this.adjustmentValid = true
      }

      return valid
    },
  },
}
</script>

<style lang="sass">
.AdjustmentModal
  background-color: $white
  border: $border
  border-radius: $border-radius
  box-shadow: $modal-shadow
  padding: rem(25px) rem(31px)
  position: absolute
  right: 0
  top: rem(49px) // 38px btn height + 11px of space
  z-index: 4

  &__adjustment-select
    width: rem(275px)

    .vs__selected
      &::before
        content: 'Type:'
        padding-right: rem(4px)
</style>
