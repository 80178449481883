<template>
  <div class="UserActivity column">
    <!-- PENDING INVOICES -->
    <transition name="Transition__fade">
      <div
        v-if="requestsCompleted"
        class="UserActivity__content-section column"
      >
        <label class="fs-16 fw-med mb-22">Pending Invoices</label>

        <template v-if="invoicesCount">
          <base-invoice-table
            class="width-100"
            :status="'pending'"
            :user-is-client="userIsClient"
          />
          <base-pagination
            @paginate="getInvoices"
            class="mt-22"
            :item-count="invoicesCount"
            :page="invoicesPage"
            :request-limit="5"
          />
        </template>

        <template v-else>
          <label class="fc-light fs-14">No invoices</label>
        </template>
      </div>
    </transition>

    <!-- TRANSFERS -->
    <transition name="Transition__fade">
      <div
        v-if="requestsCompleted"
        class="UserActivity__content-section column"
      >
        <label class="fs-16 fw-med mb-22">Transfers</label>
        <base-client-transfer-table
          class="mt-22"
          :transfers="transfers"
        />
        <base-pagination
          @paginate="getTransfers"
          class="mt-22"
          :item-count="transfersCount"
          :page="transfersPage"
          :request-limit="5"
        />
      </div>
    </transition>

    <!-- BALANCE HISTORY -->
    <transition name="Transition__fade">
      <div
        v-if="requestsCompleted"
        class="UserActivity__content-section column"
      >
        <div class="row row--align-center row--justify-between">
          <label class="fs-16 fw-med mb-22">Balance History</label>
          <div class="row row--align-center row--width-auto">
            <v-select
              v-model="balancesFilter"
              @input="getBalances()"
              aria-label="Select input to filter balances"
              class="UserActivity__balances-filter-select mr-10"
              :clearable="false"
              :options="balancesFilterOptions"
              :placeholder="'Filter by:'"
            />
            <div class="UserActivity__adjustment-wrapper">
              <base-button
                v-if="userIsEmployee"
                @click="showAdjustment = !showAdjustment"
                class="bg-green fc-white"
                data-cy="adjustment-modal-add-btn"
              >
                <i class="fa fa-add-circle fc-white" />
                Add Adjustment
              </base-button>

              <!-- ADJUSTMENT MODAL -->
              <transition name="Transition__opacity-fade">
                <adjustment-modal
                  v-if="showAdjustment"
                  @adjustment-created="getBalances(null, $event)"
                  @cancel="showAdjustment = false"
                />
              </transition>
            </div>
          </div>
        </div>
        <base-balance-table
          @load-balances="getBalances()"
          :balances="balances"
          class="mb-22 mt-22"
        />
        <base-pagination
          @paginate="getBalances"
          class="mb-22"
          :item-count="balancesCount"
          :page="balancesPage"
          :request-limit="5"
        />
      </div>
    </transition>

    <!-- BANK ACCOUNTS -->
    <transition name="Transition__fade">
      <div
        v-if="requestsCompleted"
        class="UserActivity__content-section column"
      >
        <dashboard-client-banking
          :id="client.id"
          :user-is-client="userIsClient"
          :user-is-employee="userIsEmployee"
        />
      </div>
    </transition>
  </div>
</template>

<script>
/* eslint-disable no-plusplus */
// Helpers
import {
  Balance,
  ClientPayment,
  Invoice
} from '../utils/api'
// Components
import AdjustmentModal from './adjustment-modal.vue'
import BaseBalanceTable from './base-balance-table.vue'
import BaseButton from './base-button.vue'
import BaseClientTransferTable from './base-client-transfer-table.vue'
import BaseInvoiceTable from './base-invoice-table.vue'
import BasePagination from './base-pagination.vue'
import DashboardClientBanking from '../views/dashboard/dashboard-client-banking.vue'

export default {
  name: 'UserActivity',

  components: {
    AdjustmentModal,
    BaseBalanceTable,
    BaseButton,
    BaseClientTransferTable,
    BaseInvoiceTable,
    BasePagination,
    DashboardClientBanking,
  },

  props: {
    pagePOV: {
      type: String,
      required: true,
    },
    userIsClient: {
      type: Boolean,
      required: true,
    },
    userIsEmployee: {
      type: Boolean,
      required: true,
    },
  },

  beforeRouteEnter (to, from, next) {
    const user = JSON.parse(localStorage.getItem('user'))
    if (user.client) {
      next({ name: 'dashboard-client', params: { id: user.client.id.toString() } })
    } else {
      next()
    }
  },

  async created () {
    try {
      this.clientAPIRequests()
    }
    catch (error) {
      this.CError(error)
      this.requestFailure({
        message: 'We had an issue fetching the client\'s invoices, transfers, and balance history'
      })
    }
  },

  data () {
    return {
      balances: null,
      balancesCount: null,
      balancesFilter: 'None',
      balancesFilterOptions: [
        'None',
        'Non-payment',
        'Shortpay',
        'Overpay',
        'Chargeback',
        'Adjustment',
      ],
      balancesPage: 1,
      invoicesCount: null,
      invoicesPage: 1,
      requestsCompleted: false,
      showAdjustment: false,
      transfers: null,
      transfersCount: null,
      transfersPage: 1,
    }
  },

  computed: {
    client () {
      return this.$store.getters.client
    },
  },

  methods: {
    amountColorStyle (sign) {
      return {
        'UserActivity__item-amount': true,
        success: sign === '+',
        danger: sign === '-',
      }
    },

    balanceReason () {
      if (this.balancesFilter === 'None') return null
      if (this.balancesFilter === 'Non-payment') return 'nonpayment'
      if (this.balancesFilter === 'Shortpay') return 'shortpay'
      if (this.balancesFilter === 'Overpay') return 'overpay'
      if (this.balancesFilter === 'Chargeback') return 'chargeback'
      if (this.balancesFilter === 'Adjustment') return null
    },

    async getBalances (page = null, newBalance = null) {
      this.showAdjustment = false

      if (page) this.balancesPage = page

      try {
        const balances = (await Balance.getClientBalances({
          client_id: this.client.id,
          include: 'clientpayments',
          limit: 5,
          offset: page > 1 ? (page - 1) * 5 : 0,
          reason: this.balanceReason(),
          sort_by: 'accounting',
          sort_direction: 'DESC',
          type: this.balancesFilter === 'Adjustment' ? 'client adjustments' : null,
        })).data
        const balanceData = balances.rows.map(balance => Object.assign(balance, { expanded: false }))
        // add the new balance to the front of the array if it isn't returned
        if (newBalance) {
          if (!balanceData.find(balance => newBalance.id === balance.id)) {
            balances.count++
            balanceData.unshift(newBalance)
          }
        }
        this.balances = balanceData
        this.balancesCount = balances.count
        await this.getClientBalance()
      }
      catch (error) {
        this.captureSentryEvent(
          'User Activity "Get Balances" Error',
          {
            config: error.config,
            data: this.$data,
            details: error,
            props: this.$props,
            response: error.response,
          }
        )
        this.CError(error)
        this.requestFailure({ message: 'There was an issue getting the client\'s balances' })
      }
    },

    async clientAPIRequests () {
      this.progressStart()
      await this.getBalances()
      await this.getInvoices()
      await this.getTransfers()
      this.progressFinish()
      this.requestsCompleted = true
    },

    async getClientBalance () {
      try {
        this.$store.commit('UPDATE_STORED_CLIENT', {
          balance: (await Balance.getClientTotalBalance(this.client.id)).data.total
        })
      }
      catch (error) {
        this.captureSentryEvent(
          'User Activity "Get Client Balance" Error',
          {
            config: error.config,
            data: this.$data,
            details: error,
            props: this.$props,
            response: error.response,
          }
        )
        this.CError(error)
        this.requestFailure({ message: 'There was an issue getting the client\'s balance' })
      }
    },

    async getInvoices (page = null) {
      if (page) this.invoicesPage = page

      try {
        const params = {
          client_id: this.client.id,
          forceAllInvoiceIds: true,
          is_buyout: false,
          offset: (this.invoicesPage - 1) * 5,
          sort_by: 'created_at',
          sort_direction: 'ASC',
          status: 'pending',
        }
        const invoices = (await Invoice.queryList(params, 5)).data
        // Store search in localStorage
        window.localStorage.setItem('search', JSON.stringify({
          ids: invoices.all_invoice_ids,
          params,
          url: `/client/${this.client.id}/activity`
        }))

        this.$store.commit('STORE_INVOICES', invoices)
        this.invoicesCount = invoices.count
      }
      catch (error) {
        this.captureSentryEvent(
          'User Activity "Get Invoices" Error',
          {
            config: error.config,
            data: this.$data,
            details: error,
            props: this.$props,
            response: error.response,
          }
        )
        this.CError(error)
        this.requestFailure({ message: 'There was an issue getting the client\'s current invoices' })
      }
    },

    async getTransfers (page = null) {
      if (page) this.transfersPage = page

      try {
        const transfers = (await ClientPayment.queryList({
          client_id: this.client.id,
          include: ['bankpayments', 'balance_invoices'],
          limit: 5,
          offset: page ? (page - 1) * 5 : 0,
          sort_by: 'created_at',
          sort_direction: 'DESC',
        })).data
        this.transfers = transfers.rows.map(transfer => {
          Object.assign(transfer, { expanded: false })
          // make the sign correct for UI
          transfer.invoices.forEach(invoice => {
            if (invoice.approved_invoice_adjustment_amount) {
              invoice.approved_invoice_adjustment_amount *= -1
            }
          })
          // sum the approved_invoice_adjustment_amount
          transfer.approved_invoice_adjustment_amount = transfer.invoices
            .reduce((acc, curr) => (acc + curr.approved_invoice_adjustment_amount), 0)
          return transfer
        })
        this.transfersCount = transfers.count
      }
      catch (error) {
        this.captureSentryEvent(
          'User Activity "Get Transfers" Error',
          {
            config: error.config,
            data: this.$data,
            details: error,
            props: this.$props,
            response: error.response,
          }
        )
        this.CError(error)
        this.requestFailure({ message: 'There was an issue getting the client\'s transfers' })
      }
    },

    goToInvoiceDetail (invoice) {
      // Go to invoice details page
      const route = this.$router.resolve({
        name: 'invoice-details',
        params: {
          id: invoice.id,
        }
      })
      window.open(route.href, '_blank')
    },
  }
}
</script>

<style lang="sass">
.UserActivity
  padding: 0 rem(29px) 2.5rem

  &__adjustment-wrapper
    position: relative

  &__balances-filter-select
    width: rem(225px)

    .vs__selected
      &::before
        content: 'Filter by:'
        padding-right: rem(4px)

  &__content-section
    margin-top: rem(42px)

  @media #{$tablet-landscape-and-down}
    margin-top: rem(15px)
</style>
