var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "UserActivity column" },
    [
      _c("transition", { attrs: { name: "Transition__fade" } }, [
        _vm.requestsCompleted
          ? _c(
              "div",
              { staticClass: "UserActivity__content-section column" },
              [
                _c("label", { staticClass: "fs-16 fw-med mb-22" }, [
                  _vm._v("Pending Invoices")
                ]),
                _vm.invoicesCount
                  ? [
                      _c("base-invoice-table", {
                        staticClass: "width-100",
                        attrs: {
                          status: "pending",
                          "user-is-client": _vm.userIsClient
                        }
                      }),
                      _c("base-pagination", {
                        staticClass: "mt-22",
                        attrs: {
                          "item-count": _vm.invoicesCount,
                          page: _vm.invoicesPage,
                          "request-limit": 5
                        },
                        on: { paginate: _vm.getInvoices }
                      })
                    ]
                  : [
                      _c("label", { staticClass: "fc-light fs-14" }, [
                        _vm._v("No invoices")
                      ])
                    ]
              ],
              2
            )
          : _vm._e()
      ]),
      _c("transition", { attrs: { name: "Transition__fade" } }, [
        _vm.requestsCompleted
          ? _c(
              "div",
              { staticClass: "UserActivity__content-section column" },
              [
                _c("label", { staticClass: "fs-16 fw-med mb-22" }, [
                  _vm._v("Transfers")
                ]),
                _c("base-client-transfer-table", {
                  staticClass: "mt-22",
                  attrs: { transfers: _vm.transfers }
                }),
                _c("base-pagination", {
                  staticClass: "mt-22",
                  attrs: {
                    "item-count": _vm.transfersCount,
                    page: _vm.transfersPage,
                    "request-limit": 5
                  },
                  on: { paginate: _vm.getTransfers }
                })
              ],
              1
            )
          : _vm._e()
      ]),
      _c("transition", { attrs: { name: "Transition__fade" } }, [
        _vm.requestsCompleted
          ? _c(
              "div",
              { staticClass: "UserActivity__content-section column" },
              [
                _c(
                  "div",
                  { staticClass: "row row--align-center row--justify-between" },
                  [
                    _c("label", { staticClass: "fs-16 fw-med mb-22" }, [
                      _vm._v("Balance History")
                    ]),
                    _c(
                      "div",
                      { staticClass: "row row--align-center row--width-auto" },
                      [
                        _c("v-select", {
                          staticClass:
                            "UserActivity__balances-filter-select mr-10",
                          attrs: {
                            "aria-label": "Select input to filter balances",
                            clearable: false,
                            options: _vm.balancesFilterOptions,
                            placeholder: "Filter by:"
                          },
                          on: {
                            input: function($event) {
                              return _vm.getBalances()
                            }
                          },
                          model: {
                            value: _vm.balancesFilter,
                            callback: function($$v) {
                              _vm.balancesFilter = $$v
                            },
                            expression: "balancesFilter"
                          }
                        }),
                        _c(
                          "div",
                          { staticClass: "UserActivity__adjustment-wrapper" },
                          [
                            _vm.userIsEmployee
                              ? _c(
                                  "base-button",
                                  {
                                    staticClass: "bg-green fc-white",
                                    attrs: {
                                      "data-cy": "adjustment-modal-add-btn"
                                    },
                                    on: {
                                      click: function($event) {
                                        _vm.showAdjustment = !_vm.showAdjustment
                                      }
                                    }
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "fa fa-add-circle fc-white"
                                    }),
                                    _vm._v(
                                      "\n              Add Adjustment\n            "
                                    )
                                  ]
                                )
                              : _vm._e(),
                            _c(
                              "transition",
                              { attrs: { name: "Transition__opacity-fade" } },
                              [
                                _vm.showAdjustment
                                  ? _c("adjustment-modal", {
                                      on: {
                                        "adjustment-created": function($event) {
                                          return _vm.getBalances(null, $event)
                                        },
                                        cancel: function($event) {
                                          _vm.showAdjustment = false
                                        }
                                      }
                                    })
                                  : _vm._e()
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]
                ),
                _c("base-balance-table", {
                  staticClass: "mb-22 mt-22",
                  attrs: { balances: _vm.balances },
                  on: {
                    "load-balances": function($event) {
                      return _vm.getBalances()
                    }
                  }
                }),
                _c("base-pagination", {
                  staticClass: "mb-22",
                  attrs: {
                    "item-count": _vm.balancesCount,
                    page: _vm.balancesPage,
                    "request-limit": 5
                  },
                  on: { paginate: _vm.getBalances }
                })
              ],
              1
            )
          : _vm._e()
      ]),
      _c("transition", { attrs: { name: "Transition__fade" } }, [
        _vm.requestsCompleted
          ? _c(
              "div",
              { staticClass: "UserActivity__content-section column" },
              [
                _c("dashboard-client-banking", {
                  attrs: {
                    id: _vm.client.id,
                    "user-is-client": _vm.userIsClient,
                    "user-is-employee": _vm.userIsEmployee
                  }
                })
              ],
              1
            )
          : _vm._e()
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }